import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Row, Col, DatePicker, Select, message as antMessage } from 'antd';
import { withAPI } from 'modules/api';
import {
  Table,
  DropdownMenu,
  UploadModal,
  DeleteModal,
} from 'modules/core/components';
import { GENERAL, PRODUCTIVA, UNITS } from 'modules/core/constants';

const DEFAULT_EVENT = PRODUCTIVA.DESTETE;

const { RangePicker } = DatePicker;

const EventPicker = ({ userSpecies, onChange, style }) => {
  const options =
    userSpecies === UNITS.OVINO
      ? PRODUCTIVA.OVINO_EVENT_TYPES
      : PRODUCTIVA.BOVINO_EVENT_TYPES;

  return (
    <Select
      defaultValue={DEFAULT_EVENT}
      style={style}
      onChange={onChange}
      dropdownMatchSelectWidth={200}
      options={options.map((e) => ({
        value: e,
        label: PRODUCTIVA.EVENT_FORMATTED[e],
      }))}
    />
  );
};

const UPLOAD_FILE_INIT = {
  visible: false,
  filename: null,
  params: {},
  errorData: {},
};

const EventsSync = ({ api }) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(DEFAULT_EVENT);
  const [uploadFile, setUploadFile] = useState(UPLOAD_FILE_INIT);
  const [selectedDates, setSelectedDates] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const resetSelectedItem = () => {
    setDeleteModalVisible(false);
    setSelectedItem({});
  };

  const {
    data: files,
    isLoading,
    refetch: refetchEventSync,
  } = useQuery(
    [
      'Mobile::EventSync',
      { eventType: selectedEvent, params: { ...selectedDates } },
    ],
    api.mobileEventSync
  );

  const { mutate: mobileConfirmSyncFile, isLoading: isSyncingFile } =
    useMutation(api.mobileConfirmSyncFile);
  const handleConfirmFile = ({ id, file_name }) => {
    mobileConfirmSyncFile(
      { fileId: id },
      {
        onSuccess: (data) => {
          if (data.error) {
            setUploadFile({
              visible: true,
              params: { fileId: id },
              filename: file_name.substring(0, file_name.lastIndexOf('.xlsx')),
              errorData: data,
            });
          } else {
            refetch();
            antMessage.success('Archivo confirmado!');
          }
        },
        onError: ({ description }) => antMessage.error(description),
      }
    );
  };

  // Maybe this could be done in other way
  const { refetch: refetchSyncNotifications } = useQuery(
    ['Notifications::fetchSyncNotifications'],
    api.fetchSyncNotifications,
    { enabled: false, cacheTime: 0 }
  );

  const refetch = () => {
    refetchEventSync();
    refetchSyncNotifications();
  };

  const { mutate: mobileDeleteSyncFile, isLoading: isDeleting } = useMutation(
    api.mobileDeleteSyncFile
  );
  const handleDelete = ({ onSuccess, onError }) => {
    mobileDeleteSyncFile({ fileId: selectedItem.id }, { onSuccess, onError });
  };

  // TODO: Maybe this is not the best way to handle downloaded marks
  const { mutate: markDownloaded } = useMutation(
    api.mobileEventSyncMarkDownloaded
  );
  const onDownload = ({ status, input_file, id: fileId }) => {
    if (status !== 'ended_error') {
      // FIXME: 200 should return something to not end in error
      markDownloaded({ fileId }, { onSuccess: { refetch }, onError: refetch });
    }
  };

  const columns = [
    {
      dataIndex: 'created_on',
      title: 'Fecha de carga',
      render: (d) => GENERAL.dateToFront(d, true),
    },
    {
      dataIndex: 'user',
      title: 'Usuario',
    },
    {
      dataIndex: 'model',
      title: 'Evento objectivo',
      render: (v) => PRODUCTIVA.EVENT_FORMATTED[v],
    },
    {
      dataIndex: 'input_file',
      title: 'Archivo cargado',
      render: (v, r) =>
        v ? (
          <a
            href={v}
            target="_blank"
            rel="noreferrer"
            onClick={() => onDownload(r)}
          >
            {r.file_name}
          </a>
        ) : (
          '-'
        ),
    },
    {
      dataIndex: 'confirmed',
      title: 'Confirmado',
      render: (v) => (v ? 'Si' : 'No'),
    },
    {
      title: 'Acciones',
      align: 'right ',
      render: (_t, item) => (
        <DropdownMenu
          menu={[
            {
              title: 'Confirmar',
              icon: <CheckCircleOutlined />,
              onClick: () => handleConfirmFile(item),
            },
            {
              title: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () => {
                setSelectedItem(item);
                setDeleteModalVisible(true);
              },
            },
          ]}
        />
      ),
    },
  ];

  const onChangeDates = (dates) => {
    if (dates) {
      setSelectedDates({
        startDate: GENERAL.dateToBack(dates[0]),
        endDate: GENERAL.dateToBack(dates[1]),
      });
    } else setSelectedDates(null);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={8}>
          <Col span={4}>
            <EventPicker
              userSpecies={api.userSpecies}
              style={{ width: '100%' }}
              onChange={setSelectedEvent}
            />
          </Col>
          <Col span={4}>
            <RangePicker
              style={{ width: '100%' }}
              onChange={onChangeDates}
              format={GENERAL.DATE_FORMAT.FRONT}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          rowKey="id"
          loading={isLoading || isSyncingFile}
          columns={columns}
          dataSource={files}
          rowClassName={(record) => !record.confirmed && 'bold'}
        />
        <UploadModal
          title={uploadFile.filename}
          apiFn={api.mobileUploadSyncFile}
          params={uploadFile.params}
          visible={uploadFile.visible}
          close={() => setUploadFile(UPLOAD_FILE_INIT)}
          onUpload={() => {
            refetch();
            setUploadFile(UPLOAD_FILE_INIT);
          }}
          errorData={uploadFile.errorData}
        />
        <DeleteModal
          title="Eliminar archivo"
          isDeleting={isDeleting}
          visible={deleteModalVisible}
          onCancel={resetSelectedItem}
          onConfirm={handleDelete}
          onSuccess={() => {
            refetch();
            resetSelectedItem();
          }}
          onSuccessMessage={'Archivo eliminado con éxito.'}
        />
      </Col>
    </Row>
  );
};

export default withAPI(EventsSync);
